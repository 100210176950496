<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">

    <h2 class="text-white mb-10">{{ user.first_name }} {{ user.last_name }}</h2>

    <div v-if="deletion_stage < 1" class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Ustawienia użytkownika</h3>
        </div>
      </div>
      <!--end::Card header-->
      <!--begin::Content-->
      <div class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div v-if="edit.names == false">
              <div class="fs-6 fw-bolder mb-1">Imię i nazwisko</div>
              <div class="fw-bold text-gray-600">{{ user.first_name }} {{ user.last_name }}</div>
            </div>
            <div v-if="edit.names == false" class="ms-auto">
              <button class="btn btn-primary btn-sm" @click="edit.names = true">Edytuj</button>
            </div>
            <div v-else class="flex-row-fluid">
              <div class="mb-6">
                <div class="row">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Imię i nazwisko:</label>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Imię" v-model="user.first_name" />
                      </div>
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Nazwisko" v-model="user.last_name" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end pb-6">
                <button @click="edit.names = false; this.reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="saveNames" class="btn btn-primary">Zapisz zmiany</button>
              </div>
            </div>
          </div>

          <div class="separator separator-dashed my-6"></div>

          <div class="d-flex flex-wrap align-items-center">
            <div v-if="edit.password == false">
              <div class="fs-6 fw-bolder mb-1">Hasło</div>
              <div class="fw-bold text-gray-600">**********</div>
            </div>
            <div v-if="edit.password == false" class="ms-auto">
              <button v-if="profile.is_admin" class="btn btn-primary btn-sm" @click="edit.password = true">Edytuj</button>
            </div>
            <div v-else class="flex-row-fluid">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nowe hasło:</label>
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-12 fv-row" data-kt-password-meter="true">
                      <input type="password" :class="{ 'is-invalid': errors.haslo }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Podaj nowe hasło" v-model="password.haslo" />
                      <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Potwierdzenie hasła:</label>
                <div class="col-lg-8">
                  <div class="row invalid">
                    <div class="col-lg-12 fv-row">
                      <input type="password" :class="{ 'is-invalid': errors.haslo_confirmation }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Powtórz nowe hasło" v-model="password.haslo_confirmation" />
                      <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo_confirmation }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end pb-6">
                <button @click="edit.password = false; this.reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="savePassword" class="btn btn-primary">Zapisz zmiany</button>
              </div>
            </div>
          </div>

          <div class="separator separator-dashed my-6"></div>

          <div class="d-flex flex-wrap align-items-center">
            <div v-if="edit.email == false">
              <div class="fs-6 fw-bolder mb-1">Adres e-mail</div>
              <div class="fw-bold text-gray-600">{{ user.email }}</div>
            </div>
            <div v-if="edit.email == false" class="ms-auto">
              <button v-if="profile.is_admin" class="btn btn-primary btn-sm" @click="edit.email = true">Edytuj</button>
            </div>
            <div v-else class="flex-row-fluid">
              <div class="mb-6">
                <div class="row">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">E-mail:</label>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-12 fv-row">
                        <input type="text" :class="{ 'is-invalid': errors.email }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="E-mail" v-model="user.email" />
                        <div class="fv-plugins-message-container invalid-feedback">{{ errors.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end pb-6">
                <button @click="edit.email = false; this.reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="saveEmail" class="btn btn-primary">Zapisz zmiany</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else="deletion_stage == 1" class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0 text-danger">Usuwanie użytkownika</h3>
        </div>
      </div>
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div>
              <div class="fs-6 fw-bolder mb-1">Potwierdź usunięcie użytkownika {{ user.email }}</div>
              <div class="fw-bold text-gray-600">Jeśli chcesz usunąć użytkownika, kliknij przycisk. Operacji nie można cofnąć.</div>
            </div>
          </div>
        </div>
        <div class="card-footer border-top p-9">
          <div class="float-start">
            <button class="btn btn-secondary" @click="deletion_stage = 0">Anuluj usuwanie</button>
          </div>
          <div class="float-end">
            <button class="btn btn-danger" @click="deleteAction">Potwierdź usunięcie</button>
          </div>
        </div>
    </div>

    <div class="float-start ms-auto mb-15">
      <button v-if="deletion_stage == 0" class="btn btn-white btn-sm" v-bind:class="{ 'btn-danger': deleteOver }" @mouseover="deleteOver=true" @mouseleave="deleteOver=false" @click="deletion_stage = 1">Usuń użytkownika</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersDetails',
  inject: ['$profile', '$organisation'],
  props: ['id', 'userId'],
  data() {
    return {
      user: [],
      password: {
        haslo: '',
        haslo_confirmation: ''
      },
      deletion_stage: 0,
      errors: {},
      deleteOver: false,
      edit: {
        'names': false,
        'password': false,
        'email': false
      }
    }
  },
  mounted () {
      this.reload();
  },
  methods: {
    reload() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      this.$root.$refs.api.get('organisations/' + id + '/users/' + this.userId).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.user = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        self.user = {};
      });
    },
    saveNames: function() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      var params = {
        'first_name': self.user.first_name,
        'last_name': self.user.last_name
      };
      this.$root.$refs.api.patch('organisations/' + id + '/users/' + this.userId, params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.user = response.data;
          self.errors = {};
          self.edit.names = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    savePassword: function() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      var params = {
        'aktualne_haslo': self.password.aktualne_haslo,
        'haslo': self.password.haslo,
        'haslo_confirmation': self.password.haslo_confirmation
      };
      this.$root.$refs.api.patch('organisations/' + id + '/users/' + this.userId, params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.user = response.data;
          self.errors = {};
          self.edit.password = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    saveEmail: function() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      var params = {
        'email': self.user.email
      };
      this.$root.$refs.api.patch('organisations/' + id + '/users/' + this.userId, params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.user = response.data;
          self.errors = {};
          self.edit.email = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    deleteAction: function() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      this.$root.$refs.api.delete('organisations/' + id + '/users/' + this.userId).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          if (self.profile.is_admin)
          {
            self.$router.push('/admin/organisations/' + id + '/users/');
          }
          else
          {
            self.$router.push('/dashboard/users/');
          }
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
